import React, { useState } from 'react';
import { BottomSheet, Button, ClipBoard, RunningTotalInner } from '@sky-tv-group/components';
import { konakartService, myAccountSWR } from '../services';
import { CouponTypeValue, useRunningTotal, useProductInCart, useboxesStore, BoxTypes, useTotals, formatMoney, productSkuIds } from '@sky-tv-group/shared';
import history from '../history';
export const CartCount = ({
  onCartClick,
  btnLabel,
  className,
}: {
  onCartClick: () => void;
  btnLabel: string;
  className?: string;
}) => {
  const {
    starter,
    tvPackageProducts,
    addOnProducts,
    broadbandProducts,
    voiceProducts,
    voiceAddonsProducts,
  } = useRunningTotal(konakartService);
  const boxesFromStore = useboxesStore(s => s.boxes?.filter(b => b?.boxType === BoxTypes.NEW));
  const { broadbandDevices } = useProductInCart();
  const totalCartItems =
    (starter ? 1 : 0) +
    (tvPackageProducts?.length ?? 0) +
    (addOnProducts?.length ?? 0) +
    (broadbandDevices?.length ?? 0) +
    (broadbandProducts?.length ?? 0) +
    (voiceProducts?.length ?? 0) +
    (voiceAddonsProducts?.length ?? 0) +
    (boxesFromStore?.length ?? 0);
  return (
    <button onClick={onCartClick} className={className}>
      <div className="flex flex-col justify-center items-center">
        <div className="text-navy">
          <ClipBoard />
          <span
            style={{ padding: '1px 8px 0 8px', fontWeight: 700 }}
            className="absolute text-center text-white top-1 bg-blue-pure sky-text-xs rounded-full">
            {totalCartItems}
          </span>
        </div>
        <div className="sky-text-xxs">{btnLabel}</div>
      </div>
    </button>
  );
};

export const CartSummary = () => {
  const [showBottomSheet, setBottomSheet] = useState<boolean>(false);
  const totals = useTotals(myAccountSWR);
  function handleNext(e:any){
    e.preventDefault();
    switch(window.location.pathname){
      case "/build-my-plan/checkout":
       let createUrOwnBundleNextBtn = document.getElementById("createUrOwnBundleNextBtn");
       if(createUrOwnBundleNextBtn){
       createUrOwnBundleNextBtn?.click();
       }
        break;
      case "/checkout/account-form":
       let nextInstallationbutton:any = document.getElementById('nextInstallationbutton')!;
       if(nextInstallationbutton){
        nextInstallationbutton.click();
       }
        break;
      case "/checkout/dth-form":
        let instalationDetailForm = document.getElementById('installationDeatails');
        if(instalationDetailForm){
        instalationDetailForm?.click();
        }
        break;
      default:
        break;
    }
  }
  return (
    <>
    {!sessionStorage.getItem("isBundle") ?
    <>
      <CartCount onCartClick={() => setBottomSheet(true)} btnLabel="Summary" />
      {showBottomSheet && (
        <BottomSheet isOpen={showBottomSheet} onRequestClose={() => setBottomSheet(false)}>
          <h2 className="text-navy text-center mb-4">
            <span className="sky-h3-reg">Order</span>
            <span className="sky-h3-black ml-2">Summary:</span>
          </h2>
          <RunningTotalInner
            cartType={CouponTypeValue.Acquisition}
            show={true}
            konakartService={konakartService}
            myAccountSWR={myAccountSWR}
            showNew={true}
          />
        </BottomSheet>
      )}
      </>
      : window.location.pathname !== '/checkout/confirm'?<div className='flex gap-4'>
       <div className='flex flex-col text-base font-normal'> <span>Monthly charge</span>
        <span className=' text-base text-right font-black'>{formatMoney(totals.monthlyPriceWithOffer)}/mth</span>
        </div>
        <div>
        <a href={window.location.pathname !== '/checkout/confirm' ? '/next-page' : '#'}>
        <Button
          className="text-xl font-black"
          colorMode={window.location.pathname === '/checkout/confirm' ? 'disabled' : 'pure'}
          onClick={handleNext}
          disabled={window.location.pathname === '/checkout/confirm'}
          >
          Next
        </Button>
        </a>

        {/* <Button className="text-xl font-black" colorMode={window.location.pathname==='/checkout/confirm' ?'disabled':'pure'} onClick={handleNext} disabled={window.location.pathname==='/checkout/confirm' }>Next</Button> */}
        </div>
      </div>:<></>}
    </>
  );
};
